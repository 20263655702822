<template>
  <BaseModal
    ref="modal"
    :title="editMode ? 'Medewerker bewerken' : 'Medewerker toevoegen'"
  >
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="collaboratorContactsCreateUpdateModalForm"
      @submit="submit"
    >
      <FormulateInput
        type="autocomplete"
        autoCompleteType="collaborator"
        name="collaborator"
        label="Medewerker"
        placeholder="Selecteer een medewerker"
        validation="required"
        :disabled="editMode"
      />
      <FormulateInput
        type="select"
        name="role"
        label="Rol"
        :options="roles"
        placeholder="Selecteer een rol"
        validation="required"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { errorModal, successModal, warningModal } from '@/modalMessages'
import { getCollaboratorRoles } from '@/services/organization'
import { createContactCollaborator, updateContactCollaborator } from '@/services/contacts'
export default {
  name: 'EntityCollaboratorCreateEditModal',
  props: {
    contactId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      editMode: false,
      contactCollaboratorId: null,
      roles: [],
      values: {}
    }
  },
  methods: {
    async show (contactCollaborator = null) {
      const response = await this.fetchRoles()
      this.editMode = false
      if (contactCollaborator) {
        this.editMode = true
        this.$set(this.values, 'collaborator', contactCollaborator.collaborator)
        this.contactCollaboratorId = contactCollaborator.id
        this.$set(this.values, 'role', contactCollaborator.role.id)
      }
      this.$refs.modal.show()
      return response
    },
    async fetchRoles () {
      const params = { type: [1, 2] } // type 1 = ALL, type 2  = CONTACT collaborator roles
      const response = await getCollaboratorRoles({ params })
      this.roles = response?.data?.results.map(role => { return { label: role.name_nl, value: role.id } })
      return response
    },
    async submit (data) {
      try {
        let response = null
        if (this.editMode) {
          response = await updateContactCollaborator(this.contactId, this.contactCollaboratorId, data)
          successModal('Medewerker werd succesvol gewijzigd')
        } else {
          data.collaborator = data.collaborator.id
          response = await createContactCollaborator(this.contactId, data)
          successModal('Medewerker werd succesvol aangemaakt')
        }
        this.$emit('contactCollaboratorCreatedOrUpdated')
        this.$refs.modal.hide()
        return response
      } catch (error) {
        if (error.response?.data?.code?.[0] === 'COLLABORATOR_FOR_ROLE_ALREADY_EXISTS') {
          warningModal('Er bestaat al een medewerker met de geselecteerde rol voor dit contact')
        }
        errorModal('Er ging iets mis bij het toevoegen/bewerken van de medewerker')
      }
    }
  }
}
</script>
