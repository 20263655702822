<template>
  <div>
    <a v-if="loaded" :class="{ 'btn btn-success btn-xs tw-text-white': asLabel }" @click="show">
      {{ counterPrefix }} {{ duplicateContacts.count }}
    </a>
    <button v-else type="button" class="btn btn-default btn-xs" @click="fetchDuplicateContacts">
      <i :class="['fa tw-mr-1', loading ? 'fa-spinner-third fa-spin' : 'fa-hand-pointer-o']"/>Duplicate contacten laden
    </button>

    <Modal ref="modal" title="Contact samenvoegen" size="xl">
      <DataTable
        :loading="loading"
        :headers="headers"
        v-bind="duplicateContacts"
        class="tw--m-5 tw-shadow-none"
        @changePage="fetchDuplicateContacts"
      >
        <template #item-actions="{ item }">
          <button
            type="button"
            :title="
              item.type === contact.type
                ? 'Contact samenvoegen'
                : 'Enkel mogelijk voor contacten van hetzelfde type'
            "
            :disabled="item.type !== contact.type"
            class="action tw-bg-success"
            @click="showMergeModal(item)"
          >
            <i class="far fa-compress-alt" />
          </button>
        </template>
      </DataTable>
    </Modal>
    <ContactMergeModalV2
      ref="mergeModal"
      @hide="hideMergeModal"
      @contacts-merged="handleContactsMerged"
    />
  </div>
</template>

<script>
import DataTable from '@/components/DataTable'
import Modal from '@/components/iam/Modal'

import ContactMergeModalV2 from '@/components/contacts/ContactMergeModalV2'
import ContactAddress from '@/components/contacts/contact_render_components/ContactAddress'
import ContactCity from '@/components/contacts/contact_render_components/ContactCity'
import ContactEmail from '@/components/contacts/contact_render_components/ContactEmail'
import ContactGDPR from '@/components/contacts/contact_render_components/ContactGDPR'
import ContactName from '@/components/contacts/contact_render_components/ContactName'

import { createLog } from '@/services/apiService'
import { getContactDuplicates } from '@/services/contacts'
import { errorModal } from '@/modalMessages'

export default {
  name: 'ContactDuplicateCounter',
  components: {
    ContactMergeModalV2,
    Modal,
    DataTable
  },
  props: {
    contact: {
      required: true,
      type: Object
    },
    // Show the counter as a label or just as number
    asLabel: {
      default: false,
      type: Boolean
    },
    // Prefix to show before the counter
    counterPrefix: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      loading: false,
      loaded: false,
      // The object duplicateContacts includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      duplicateContacts: {
        count: null,
        next: null,
        previous: null,
        results: []
      }
    }
  },
  computed: {
    headers () {
      return [
        { value: 'id', text: 'Naam', renderComponent: ContactName },
        { value: 'address', text: 'Adres', renderComponent: ContactAddress },
        { value: 'residence', text: 'Woonplaats', renderComponent: ContactCity },
        { value: 'email', text: 'E-mail', renderComponent: ContactEmail },
        { value: 'phone', text: 'Telefoon' },
        { value: 'mobile', text: 'GSM' },
        { value: 'gdpr', text: 'GDPR', renderComponent: ContactGDPR },
        { value: 'actions', text: 'Actie' }
      ]
    }
  },
  methods: {
    show () {
      this.$refs.modal.show()
    },
    hide () {
      this.$refs.modal.hide()
    },

    /*
      Unfortunately, using two modals which are stacked (mergemodal and contactlistmodal) can cause the bootstrap modal to glitch.
      Second modal that is opened, does not recieve focus.
      We tried using $nextTick to ensure all DOM updates had taken place, but jQuery updates are not tracked by Vue, so it doesn't work.
      setTimeout() seems to work the best for now, but it's a "recommended" hack for jQuery updates.
    */
    async showMergeModal (value) {
      this.hide()
      await new Promise(resolve => setTimeout(resolve, 500)) // Delay
      await this.$refs.mergeModal.show(this.contact.id, value.id)
    },
    hideMergeModal (value) {
      setTimeout(this.show, 500)
    },
    async handleContactsMerged () {
      this.hideMergeModal()
      return await this.fetchDuplicateContacts()
    },

    async fetchDuplicateContacts (payload) {
      try {
        this.loading = true
        const response = await getContactDuplicates({ contactId: this.contact.id, ...payload })
        this.duplicateContacts = response.data
        this.loading = false
        this.loaded = true
        const logPayload = {
          action_id: 44
        }
        await createLog(logPayload)
        return response
      } catch (error) {
        errorModal('Fout bij ophalen van dubbele contacten, probeer het opnieuw.')
      }
    }
  }
}
</script>
