<template>
  <BaseModal
    ref="modal"
    :title="editMode ? 'Contactlink bewerken' : 'Contactlink toevoegen'"
  >
    <FormulateForm
      #default="{ isLoading }"
      v-model="values"
      name="contactRelationshipCreateEditModalForm"
      @submit="submit"
    >
      <FormulateInput
        type="autocomplete"
        autoCompleteType="contact"
        name="contact"
        label="Contact"
        placeholder="Selecteer een contact"
        validation="required"
        :disabled="editMode"
      />
      <FormulateInput
        type="select"
        name="role"
        label="Rol"
        :options="roles"
        placeholder="Selecteer een rol"
        validation="required"
      />
      <FormulateInput
        type="submit"
        :disabled="isLoading"
        title="Opslaan"
        outer-class="tw-my-4 tw-float-right"
      >
        <i
          :class="[
            'fas tw-mr-2',
            isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
          ]"
        />
        Opslaan
      </FormulateInput>
    </FormulateForm>
  </BaseModal>
</template>

<script>
import { errorModal, successModal, warningModal } from '@/modalMessages'
import { getContactRoles, createContactRelationship, updateContactRelationship } from '@/services/contacts'
export default {
  name: 'ContactRelationshipCreateEditModal',
  props: {
    contactId: {
      type: [String, Number]
    }
  },
  data () {
    return {
      editMode: false,
      contactRelationshipId: null,
      roles: [],
      values: {}
    }
  },
  methods: {
    async show (contactRelationship = null) {
      const response = await this.fetchRoles()
      this.editMode = false
      if (contactRelationship) {
        this.editMode = true
        this.$set(this.values, 'contact', contactRelationship.acting_contact)
        this.contactRelationshipId = contactRelationship.id
        this.$set(this.values, 'role', contactRelationship.role.id)
      }
      this.$refs.modal.show()
      return response
    },
    async fetchRoles () {
      const params = { type: [1, 2] } // type 1 = ALL, type 2  = CONTACT collaborator roles
      const response = await getContactRoles({ params })
      this.roles = response?.data?.results.map(role => { return { label: role.name_nl, value: role.id } })
      return response
    },
    async submit (data) {
      try {
        let response = null
        if (this.editMode) {
          response = await updateContactRelationship(this.contactId, this.contactRelationshipId, data)
          successModal('Contactlink werd succesvol aangepast')
        } else {
          if (parseInt(data.contact.id) === parseInt(this.contactId)) {
            return warningModal('Contact kan niet aan zichzelf gelinkt worden.')
          }
          data.acting_contact = data.contact.id
          response = await createContactRelationship(this.contactId, data)
          successModal('Contact werd succesvol gelinkt')
        }
        this.$emit('contactRelationshipCreatedOrUpdated')
        this.$refs.modal.hide()
        return response
      } catch (error) {
        errorModal('Er ging iets mis bij het toevoegen/bewerken van het contact')
      }
    }
  }
}
</script>
